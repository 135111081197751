<template>
  <div class="system-setting">
    <router-header :routerHeaderInfo="routerHeaderInfo"></router-header>
    <a-steps direction="vertical">
      <a-step status="wait">
        <template #title>
          管理员登录 <a-button type="link"
                    target="_blank"
                    href="https://work.weixin.qq.com/wework_admin/frame#index">企业微信后台
          </a-button>。
        </template>
      </a-step>
      <a-step title="依次点击：客户联系 - 权限配置 - 使用范围 - 修改，将使用范围设置为公司全员(选择根目录)" />
      <a-step title="依次点击：应用管理 - EduSoho销客助手 - 可见范围 - 修改，将可见范围设置为公司全员(选择根目录)" />
      <a-step title="在应用管理中打开EduSoho销客助手，依次点击：配置到聊天侧边栏-配置页面" />
      <a-step title="填写页面名称，并将下方对应的链接复制到自定义地址中，点击确定">

      </a-step>
    </a-steps>
    <div class="copy-item"
         v-for="item in copyList"
         :key="item.title">
      <p class="mb3"> 页面名称：{{ item.title }} <span class="ml2 color-primary cursor-pointer"
              @click="copy(item.title)">复制</span>
      </p>
      {{ item.url }} <span class="ml2 color-primary cursor-pointer"
            @click="copy(item.url)">复制</span>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { Steps } from "ant-design-vue";
import { copy } from "@/global";

import RouterHeader from "@/components/RouterHeader";

export default {
  name: "ChatToolbar",
  components: {
    ASteps: Steps,
    AStep: Steps.Step,
    RouterHeader,
  },

  data() {
    const routerHeaderInfo = [
      {
        path: "/enterprise/systemSetting",
        name: "系统设置",
      },
      { name: "聊天工具栏" },
    ];
    return {
      copy,
      routerHeaderInfo,
      copyList: [
        {
          title: "快捷回复",
          url: "https://scrm.edusoho.com/customer-h5/corpWords",
        },
        {
          title: "客户画像",
          url: "https://scrm.edusoho.com/customer-h5",
        },
        {
          title: "商品订单",
          url: "https://scrm.edusoho.com/customer-h5/goodsOrderList",
        },
        {
          title: "工作台",
          url: "https://scrm.edusoho.com/customer-h5/workbench",
        },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const length = vm.routerHeaderInfo.length;
      const path = vm.routerHeaderInfo[length - 2].path;
      if (!_.includes(["/", path], from.path)) {
        const {
          path,
          meta: { name },
          query,
        } = from;

        _.assign(vm.routerHeaderInfo[length - 2], {
          path,
          name,
          query,
        });
      }
    });
  },
};
</script>
<style lang="less" scoped>
.copy-item {
  padding: 12px 16px;
  margin-bottom: 16px;
  width: 680px;
  background-color: @bg-normal;
}
</style>
